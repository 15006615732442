import { addressSourceEnum } from '@/constant/enum.js'

const searchFields = vm => {
  return [
    {
      keyId: 7,
      title: '地址',
      field: 'addrNm',
      width: 300,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 19,
      title: '城市',
      field: 'city',
      width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 11,
      title: '创建日期',
      field: 'createDate',
      width: 200,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
  ]
}

const baseColumn = vm => {
  return [
    {
      keyId: 7,
      title: '地址',
      field: 'addrNm',
      width: 300,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },

    {
      keyId: 10,
      title: '省份',
      field: 'province',
      width: 100,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 19,
      title: '城市',
      field: 'city',
      width: 100,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 20,
      title: '区县',
      field: 'county',
      width: 100,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    // {
    //   keyId: 21,
    //   title: '经纬度',
    //   field: 'laAndLg',
    //   // width: 300,
    //   searchType: 'input',
    //   searchValue: '', //搜索绑定值
    //   sortable: true, //是否排序
    //   copy: true, //是否拥有复制功能
    // },
    {
      keyId: 22,
      title: '经度',
      field: 'lng',
      minWidth: 200,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 23,
      title: '纬度',
      field: 'lat',
      minWidth: 200,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 26,
      title: '来源',
      field: 'source',
      width: 120,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      selectOptions: addressSourceEnum,
    },
    {
      keyId: 11,
      title: '创建日期',
      field: 'createDt',
      width: 200,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },

  ]
}

const operateList = vm => {
  return [
    { name: '添加', btnFn: vm.addAddress },
    { name: '删除', btnFn: vm.deleteAddress },
  ]
}

const tableData = [
  {
    address: '杭州IFC',
    province: '浙江省',
    city: '杭州市',
    area: '临平区',
    longitude: '120.305528',
    latitude: '30.402847',
    laAndLg: '120.305528,30.402847',
    source: 1,
    createAt: '2021-12-24 00:12:34'
  }
]
const total = 1
const summary = {
  source: 1,
  instQty: 100,
  instVol: 10,
  instWei: 200,
  outFee: 1500,
  outId: 1,
  payCardFee: 400
}

export default {
  operateList,
  baseColumn,
  searchFields,
  tableData,
  total,
  summary
}