import request from '@/utils/http.js'

/**
 * 地址库管理分页查询
 * @param {object} data 
 * @returns 
 */
export const queryAddressSequence = data => {
  return request({
    url: '/base/cnor/oms/queryAddressSequence',
    method: 'post',
    data
  })
}

/**
 * 新增地址库
 * @param {object} data 
 * @returns 
 */
export const addAddressSequence = data => {
  return request({
    url: '/base/cnor/oms/addAddressSequence',
    method: 'post',
    data
  })
}
/**
 * 删除地址库
 * @param {object} data 
 * @returns 
 */
export const deleteAddressSequence = data => {
  return request({
    url: '/base/cnor/oms/deleteAddressSequence',
    method: 'post',
    data
  })
}

/**
 * 收货方管理
 * @param {object} data 
 * @returns 
 */
export const inquireTheReceivingParty = data => {
  return request({
    url: '/base/rcvr/oms/inquireTheReceivingParty',
    method: 'post',
    data
  })
}

/**
 * 新增收货方管理
 * @param {object} data 
 * @returns 
 */
export const addReceivingParty = data => {
  return request({
    url: '/base/rcvr/oms/addReceivingParty',
    method: 'post',
    data
  })
}
/**
 * 获取省市区列表
 * @param {object} data 
 * @returns 
 */
export const queryAllAreaList = data => {
  return request({
    url: '/base/area/queryAllAreaList',
    method: 'post',
    data
  })
}

/**
 * 高德地图搜索关键字
 * @param {object} data 
 * @returns 
 */
export const mapTextSearch = data => {
  return request({
    url: '/amap/v5/place/text',
    method: 'get',
    params: data
  })
}
