<template>
  <div class="address-mange">
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="loading"
      :searchFields="searchFields"
      :colSize="colSize"
      :total="total"
      :autoBtnList="autoBtnList"
      :tableData="tableData"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="false"
      :showColumnSearch="false"
      :showSortable="false"
      :tableSummary="tableSummary"
      :initFormData="initFormData"
      :proxyParams="proxyParams"
      :selectTableSummary="selectTableSummary"
      :fieldOperateList="fieldOperateList"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @getTableData="getTableData"
    ></search-table>

    <center-dialog
      :visible.sync="addVisible"
      title="地址添加"
      top="20vh"
      @close="resetForm"
      :confirm="confirmDetailAddr"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="地址" prop="addrNm">
          <!-- <el-input
            v-model="form.addrNm"
            type="text"
            size="small"
            clearable
            style="width: 100%"
            placeholder="请输入详细地址"
          >
            <el-button
              slot="append"
              icon="el-icon-map-location"
              @click="showMap"
            />
          </el-input> -->
          <el-select
            class="address-info"
            v-model="form.addrNm"
            filterable
            remote
            reserve-keyword
            placeholder="请输入详细地址"
            :remote-method="addressRemoteMethod"
            :loading="addressLoading"
            @change="handleRcvrAddrChange"
          >
            <el-option
              v-for="item in addressOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button
            plain
            icon="el-icon-map-location"
            @click="showMap"
          />
        </el-form-item>
      </el-form>
    </center-dialog>
    <a-map
      ref="amap"
      :visible.sync="visible"
      :isEdit="isAddressEdit"
      @confirm="confirmCallBack"
    ></a-map>
  </div>
</template>

<script>
import SearchTable from '@/components/LcSearchTable'
import AMap from '@/components/DetailAMap/index.vue'
import address from '@/const/base-info/address.js'
import CenterDialog from '@/components/Common/CenterDialog.vue'
import { latestWeek, isParamsNotNull } from '@/utils/util.js'
import {
  queryAddressSequence,
  addAddressSequence,
  deleteAddressSequence,
  mapTextSearch
} from '@/api/baseInfo.js'
export default {
  name: 'AddressManage',
  components: {
    SearchTable,
    AMap,
    CenterDialog
  },
  data() {
    return {
      visible: false, // 地图
      addVisible: false,
      form: {
        addrNm: '',
      },
      rules: {
        addrNm: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ]
      },
      addressOptions: [], // 详细地址的搜索列表
      addressLoading: false, // 详细地址搜索的loading
      isAddressEdit: false, // 输入框有选中地址

      loading: false,
      total: 0,
      initFormData: {
        createDate: latestWeek
      },
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableData: [],
      // footerData: [], //表格尾部合计数据
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      formParams: {}, // 用于添加地址成功后刷新数据
      colSize: 4, //用户设置展示列比例
      selectTableSummary: ['index', 'addrNm', 'source'] // 需要选中合计的字段
    }
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return address.operateList(this)
    },
    // 基础表格配置项
    baseColumns() {
      return address.baseColumn(this)
    },
    // 单元格field跳转的处理  key是field，value必须是函数
    fieldOperateList() {
      return {
        address: this.getOrderDetail // 必须是函数
      }
    }
  },

  created() {
    this.tableColumns = address.baseColumn()
    this.searchFields = address.searchFields()

  },
  methods: {
    // 添加地址
    addAddress() {
      this.addVisible = true
    },
    // 删除地址
    async deleteAddress() {
      let length = this.$refs.searchTable.selectTableData.length
      
      if (length) {
        const cnorAddrIds = this.$refs.searchTable.selectTableData.reduce((prev, v) => {
          prev.push(v.cnorAddrId)
          return prev
        }, [])
        try {
          await this.$confirm('确定删除此地址?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          //todos 删除地址
          const res = await deleteAddressSequence(cnorAddrIds)
          if(res.data) {
            this.$message.success('删除地址成功')
            this.getTableData(this.formParams)
          }
        } catch (error) {
          console.log(err)
        }
      } else {
        this.$message.error('请选中需要删除的地址!')
      }
    },
    // 展示地图
    async showMap() {
      this.visible = true
      // await this.$nextTick()
      this.$refs.amap.showMap()
    },
    // 地址确认
    async confirmDetailAddr() {
      try {
        await this.$refs.form.validate()
        const res = await addAddressSequence(this.form)
        console.log(res)
        this.getTableData(this.formParams)
      } catch (error) {
        return false
      }
    },
    // 地图确认回调
    confirmCallBack(form) {
      // console.log(form)
      this.form.addrNm = form.address
      this.form.lng = form.longitude
      this.form.lat = form.latitude
      this.form.province = form.province
      this.form.city = form.city
      this.form.county = form.district
      this.form.aeName = form.district
      this.form.aeCode = form.adCode
    },
    async resetForm() {
      await this.$nextTick()
      this.$refs['form'].resetFields();
    },

    // 查看详情
    getOrderDetail(row, column) {
      console.log(row, column)
    },
    
    //获取表格数据
    async getTableData(params) {
      
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params = isParamsNotNull(params)
      if (params.createDate && params.createDate.length) {
        params.creatDateStart = params.createDate[0]
        params.creatDateEnd = params.createDate[1]
        // params.sendDt = null
      } else {
        this.$message.error('请选择时间')
        return
      }
      this.loading = true
      
      try {
        const res = await queryAddressSequence({ ...params })
        this.tableData = res.data.items
        this.total = res.data.total
        this.tableSummary = res.data.summary
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      console.log(fields)
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      console.log(fields, colSize)
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },
    
    //获取表格头配置
    async getTableField() {
      
    },
    //获取搜索字段配置
    async getSearchField() {
      
    },
    // 高德地图搜索详细地址
    async addressRemoteMethod(value) {
      // console.log(value)
      if (value !== '') {
        this.loading = true;
        try {
          const res = await mapTextSearch({
            key: process.env.VUE_APP_MAP_KEY,
            keywords: value
          })
          // console.log(res)
          this.loading = false
          this.addressOptions = res.data.pois.map(item => {
            return { ...item, label: item.name, value: item.id }
          })
          
        } catch (error) {
          this.loading = false
          console.log(error)
          this.addressOptions = []
        }
        
      } else {
        this.addressOptions = []
        this.isAddressEdit = false
      }
    },
    // 选中详细地址
    handleRcvrAddrChange(value) {
      if(!value) {
        return
      }
      const address = this.addressOptions.find(v => v.id === value)
      this.form.addrNm = address.name
      this.form.province = address.pname
      this.form.city = address.cityname
      this.form.county = address.adname
      const location = address.location.split(',')
      this.form.lng = location[0]
      this.form.lat = location[1]
      this.form.aeName = address.adname
      this.form.aeCode = address.adcode
      this.isAddressEdit = true
      this.$refs.amap.form.longitude = this.form.lng
      this.$refs.amap.form.latitude = this.form.lat
    }
    
  }
}
</script>

<style lang="scss" scoped>
  .address-info {
    width: calc(100% - 120px);
  }
</style>