<template>
  <div class="receiver-mange">
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="loading"
      :searchFields="searchFields"
      :colSize="colSize"
      :total="total"
      :autoBtnList="autoBtnList"
      :tableData="tableData"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :checkboxColumn="false"
      :hasPagination="true"
      :showFooter="false"
      :showSetting="false"
      :showColumnSearch="false"
      :showSortable="false"
      :tableSummary="tableSummary"
      :proxyParams="proxyParams"
      :selectTableSummary="selectTableSummary"
      :fieldOperateList="fieldOperateList"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @getTableData="getTableData"
    > 
    </search-table>
    <add-info ref="addInfo" @getList="getTableData"></add-info>
    
  </div>
</template>

<script>
import SearchTable from '@/components/LcSearchTable'
import AddInfo from './components/AddInfo.vue'

import address from '@/const/base-info/receiverManage.js'
import { inquireTheReceivingParty } from '@/api/baseInfo.js'
import { isParamsNotNull } from '@/utils/util.js'
export default {
  name: 'ReceiverManage',
  components: {
    SearchTable,
    AddInfo
  },
  data() {
    return {

      loading: false,
      total: 0,
      initFormData: {},
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableData: [],
      // footerData: [], //表格尾部合计数据
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 4, //用户设置展示列比例
      selectTableSummary: ['index', 'address', 'source'], // 需要选中合计的字段
      formParams: {}
    }
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return address.operateList(this)
    },
    // 基础表格配置项
    baseColumns() {
      return address.baseColumn(this)
    },
    // 单元格field跳转的处理  key是field，value必须是函数
    fieldOperateList() {
      return {
        address: this.getOrderDetail // 必须是函数
      }
    }
  },

  created() {
    // mock
    this.tableColumns = address.baseColumn(this)
    this.searchFields = address.searchFields()


  },
  methods: {
    // 添加地址
    addAddress() {
      this.$refs.addInfo.addVisible = true
    },
    // 删除地址
    async deleteAddress() {
      let length = this.$refs.searchTable.selectTableData.length
      if (length) {
        if (length === 1) {
          try {
            await this.$confirm('确定删除此地址?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            //todos 删除地址
          } catch (error) {
            console.log(err)
          }
        } else {
          this.$message.error('只能选中一个地址!')
        }
      } else {
        this.$message.error('请选中需要删除的地址!')
      }
    },

    // 查看详情
    getOrderDetail(row, column) {
      console.log(row, column)
    },
    
    //获取表格数据
    async getTableData(params) {
      
      this.loading = true
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params = isParamsNotNull(params)
      try {
        const res = await inquireTheReceivingParty({ ...params })
        this.tableData = res.data.items
        this.total = res.data.total
        this.tableSummary = res.data.summary
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      console.log(fields)
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      console.log(fields, colSize)
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },
    
    //获取表格头配置
    async getTableField() {
      
    },
    //获取搜索字段配置
    async getSearchField() {
      
    },
    
  }
}
</script>

<style>

</style>