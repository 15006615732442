<template>
  <el-dialog
    class="v-dialog"
    :title="title"
    :visible.sync="show"
    :show-close="true"
    :width="width"
    :close-on-click-modal="false"
    :close-on-press-escape="!loading"
    :append-to-body="appendToBody"
    @close="closeDialog"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- <span> -->
      <div id="map-container" class="map-cls" style="margin-top: -10px;">
        <el-input
          id="pickerInput"
          v-model="poiValue"
          type="text"
          size="small"
          class="search-box-map"
        >
          <el-button slot="append" type="primary" icon="el-icon-search" />
        </el-input>
        <el-amap
          :amap-manager="amapManager"
          :events="events"
          vid="amapDemo"
          class="amap-demo"
        />
      </div>
    <!-- </span> -->
    <span slot="footer" class="dialog-footer">
      <slot name="footer"></slot>
      <template v-if="isConfirmType">
        <el-button
          plain
          icon="el-icon-close"
          :loading="loading"
          @click="handleClose('cancel', 'cancel')"
          >{{ cancelText }}</el-button
        >
        <el-button
          plain
          type="primary"
          icon="el-icon-check"
          :loading="loading"
          @click="handleClose('confirm', 'confirm')"
          >{{ confirmText }}</el-button
        >
      </template>
      <el-button
        v-else
        plain
        icon="el-icon-close"
        @click="handleClose('close', 'close')"
        >{{ closeText }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import VueAMap, { lazyAMapApiLoaderInstance, initAMapApiLoader } from 'vue-amap'
const amapManager = new VueAMap.AMapManager()
/**
 * @type {Object}
 * @description 弹窗类型
 */
const DialogTypes = {
  confirm: 'confirm',
  alert: 'alert'
}

/**
 *
 * 通用`el-dialog`弹窗
 *
 * Slots:
 * - `default` 内容
 * - `footer` 底部按钮栏
 *
 * Events:
 *  - `@canceled` 已取消
 *  - `@confirmed` 已确认
 *  - `@closed` 已关闭
 *
 * Callback(props):
 *  - `cancel` 取消前置回调
 *  - `confirm` 确认前置回调
 *
 */
export default {
  name: 'AMap',
  props: {
    top: {
      type: String,
      default: '8vh'
    },
    title: {
      type: String,
      default: '详细地址标注'
    },
    width: {
      type: String,
      default: '50%'
    },
    type: {
      type: String,
      default: 'confirm',
      validator: value => Object.values(DialogTypes).includes(value)
    },
    // 
    isEdit: {
      type: Boolean,
      default: false
    },
    // 弹窗显示
    visible: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    closeText: {
      type: String,
      default: '关闭'
    },
    // confirm: 取消前置异步回调，返回`false`取消操作
    cancel: Function,
    // confirm: 确认前置异步回调，返回`false`取消操作
    confirm: Function,
    // alert: 关闭前置异步回调，返回`false`取消操作
    close: Function,
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isConfirmType() {
      return this.type === DialogTypes.confirm
    },
    show: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  data() {
    const self = this
    return {
      loading: false,
      // mapDialogVisible: false, 
      form: {
        longitude: 0,
        latitude: 0,
        address: '',
        province: '',
        city: '',
        district: '',
      },
      /** ******** 小地图开始展示数据 **********/
      // 小地图主要有三个数据需要保存，分别是lng/lat/aeId
      amapManager,
      poiValue: '',
      // isEdit: false,
      aeId: 0,
      positionPicker: null,
      infoWindow: null,
      events: {
        complete() {
          var map = self.amapManager.getMap()
          if (self.isEdit) {
            self.location = []
            if (self.form.longitude > 0 && self.form.latitude > 0) {
              self.location.push(self.form.longitude)
              self.location.push(self.form.latitude)
              map.setCenter(self.location)
              map.setZoom(14)
              self.positionPicker.start(self.location)
            }
          }
        },
        init(map) {
          lazyAMapApiLoaderInstance.load().then(() => {
            if (!self.isEdit) {
              self.setMapCity(map)
            }
            // 加载PoiPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
            AMapUI.loadUI(['misc/PoiPicker'], function(PoiPicker) {
              var poiPicker = new PoiPicker({
                input: 'pickerInput' // 输入框id
              })
              // 监听poi选中信息
              poiPicker.on('poiPicked', function(poiResult) {
                // 用户选中的poi点信息
                var poi = poiResult.item
                self.poiValue = poi.name
                self.form.longitude = poi.location.lng
                self.form.latitude = poi.location.lat
                self.form.address = poi.address
                self.location = []
                self.location.push(self.form.longitude)
                self.location.push(self.form.latitude)
                map.setCenter(self.location)
                map.setZoom(14)
                self.positionPicker.start(self.location)

                var info = []
                // info.push('<div style="padding:5px 0px 0px 0px;"><h4>' + self.poiValue + '</h4>')
                info.push("<p style='font-size: 12px;'>经度： " + self.form.longitude + '</p>')
                info.push("<p style='margin-top: -5px;font-size: 12px;'>纬度： " + self.form.latitude + '</p>')
                info.push("<p style='margin-top: -5px;font-size: 12px;'>地址：" + self.form.address + '</p></div>')

                self.infoWindow = new AMap.InfoWindow({
                  content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
                  offset: new AMap.Pixel(0, -31)
                })
                self.infoWindow.open(map, self.location)
              })
            })
            // 加载PositionPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
            AMapUI.loadUI(['misc/PositionPicker'], function(PositionPicker) {
              self.positionPicker = new PositionPicker({
                mode: 'dragMarker', // 设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
                map: map// 依赖地图对象
              })
              // 事件绑定、结果处理等
              self.positionPicker.on('success', function(positionResult) {
                // console.log(positionResult, '---')
                self.form.longitude = positionResult.position.lng
                self.form.latitude = positionResult.position.lat
                self.location = []
                self.location.push(self.form.longitude)
                self.location.push(self.form.latitude)
                self.form.address = positionResult.address
                const addressComponent = positionResult.regeocode.addressComponent
                self.form.province = addressComponent.province
                self.form.city = addressComponent.city
                self.form.district = addressComponent.district
                self.form.adCode = addressComponent.adcode
                var info = []
                // info.push('<div style="padding:5px 0px 0px 0px;"><h4>' + self.poiValue + '</h4>')
                info.push("<p style='font-size: 12px;'>经度： " + self.form.longitude + '</p>')
                info.push("<p style='margin-top: -5px;font-size: 12px;'>纬度： " + self.form.latitude + '</p>')
                info.push("<p style='margin-top: -5px;font-size: 12px;'>地址：" + self.form.address + '</p></div>')

                self.infoWindow = new AMap.InfoWindow({
                  content: info.join(''), // 使用默认信息窗体框样式，显示信息内容
                  offset: new AMap.Pixel(0, -31)
                })
                self.infoWindow.open(map, self.location)
              })
            })
          
          }).catch((err) => {
            console.log(err)
          })
        }
      }
      /** ******* 小地图展示数据结束 **********/
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    async handleClose(type, event) {
      this.loading = true

      let shouldClose = true

      // 前置回调
      const handleCb = this[type]
      if (handleCb) {
        try {
          shouldClose = (await handleCb()) !== false
        } catch (e) {
          shouldClose = false
        }
      }

      // 关闭弹窗
      if (event && shouldClose) {
        this.show = false
        this.$emit(event, this.form)
        
      }

      this.loading = false
    },
    async showMap() {
      // await this.$nextTick()
      var self = this
      var map = this.amapManager.getMap()
      if (self.positionPicker !== null) {
        self.positionPicker.stop()
      }
      // 在前端进行非空判定的时候一定要注意undefined的情况，否则就容易出错
      if (self.infoWindow !== null) {
        self.infoWindow.close()
      }
      this.poiValue = ''
      if (map !== null) {
        if (this.form.address === '') {
          this.setMapCity(map)
        }
        if (this.isEdit) {
          if (this.form.address !== '' && self.form.longitude > 0 && self.form.latitude > 0) {
            self.location = []
            self.location.push(self.form.longitude)
            self.location.push(self.form.latitude)
            map.setCenter(self.location)
            map.setZoom(14)
            self.positionPicker.start(self.location)
          }
          if (self.form.longitude === null && self.form.latitude === null) {
            this.setMapCity(map)
          }
        }
      }
    },
    // 小地图展示
    setMapCity(map) {
      AMap.plugin('AMap.CitySearch', function() {
        var citySearch = new AMap.CitySearch()
        citySearch.getLocalCity(function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // 查询成功，result即为当前所在城市信息
            map.setCity(result.city)
          } else {
            map.setCity('杭州市')
          }
        })
      })
    },
  },
  deactivated() {
    this.show = false
  }
}
</script>

<style lang="scss" scoped>
#map-container{
  width: 100%;
  height: 100%;
}
.amap-demo {
  max-width: 100%;
  max-height: 100%;
  width: 800px;
  height: 500px;
  margin: 0 auto;
}
.search-box-map {
  z-index: 100;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  width: 300px;
  font-size: 14px;
}

</style>