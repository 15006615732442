<template>
  <div>
    <center-dialog
      :visible.sync="addVisible"
      title="创建收货方信息"
      @close="resetForm"
      top="20vh"
      :confirm="confirm"
    >
      <el-form 
        ref="form"
        :model="form"
        :rules="rules"
        size="mini"
        label-width="90px"
      >
        <el-row>
          <el-col :span="span">
            <el-form-item label="收货单位" prop="rcvrNm">
              <el-input
                v-model.trim="form.rcvrNm"
                placeholder="请输入收货单位"
                style="width: 100%"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item label="收货人" prop="rcvrCtcts">
              <el-input
                v-model.trim="form.rcvrCtcts"
                placeholder="请输入收货人"
                style="width: 100%"
                maxlength="10"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="span">
            <el-form-item label="手机号码" prop="rcvrTel">
              <el-input
                v-model="form.rcvrTel"
                placeholder="请输入手机号"
                maxlength="11"
                clearable
              >
                <!-- <el-select
                  v-model="form.receiverPhonePrepend"
                  slot="prepend"
                  placeholder="请选择"
                  size="mini"
                  style="100px"
                >
                  <el-option label="+86" value="86"></el-option>
                  <el-option value="862">+86</el-option>
                </el-select> -->
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item label="省/市/区" prop="aeName">
              <!-- <el-cascader
                v-model.trim="form.aeName"
                :options="regionData"
                filterable
                placeholder="请选择收货城市"
                style="width: 100%"
                clearable
              ></el-cascader> -->
              <el-input
                :value="`${form.province}${form.city}${form.aeName}`"
                placeholder="请选择收货城市"
                disabled
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="详细地址" prop="rcvrAddr">
          <!-- <el-input
            v-model.trim="form.rcvrAddr"
            type="text"
            size="small"
            clearable
            style="width: 100%"
            placeholder="请输入详细地址"
          >
            <el-button
              slot="append"
              type="primary"
              icon="el-icon-map-location"
              @click="showMap"
            />
          </el-input> -->
          <el-select
            class="address-info"
            v-model="form.rcvrAddr"
            filterable
            remote
            reserve-keyword
            placeholder="请输入详细地址"
            :remote-method="addressRemoteMethod"
            :loading="loading"
            @change="handleRcvrAddrChange"
          >
            <el-option
              v-for="item in addressOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button
            plain
            icon="el-icon-map-location"
            @click="showMap"
          />
        </el-form-item>
      </el-form>
    </center-dialog>
    <a-map
      ref="amap"
      :visible.sync="visible"
      :isEdit="isAddressEdit"
      :confirm="confirmDetailAddr"
      @confirm="confirmCallBack"
    ></a-map>
  </div>

</template>

<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
import AMap from '@/components/DetailAMap/index.vue'
import CenterDialog from '@/components/Common/CenterDialog.vue'
import { addReceivingParty, queryAllAreaList, mapTextSearch } from '@/api/baseInfo.js'
import request from '@/utils/http.js'
export default {
  components: {
    AMap,
    CenterDialog
  },
  data() {
    return {
      visible: false, // 地图
      addVisible: false,
      form: {
        receiverPhonePrepend: 86,
        rcvrNm: '',
        rcvrCtcts: '',
        aeName: '',
        aeCode: '',
        rcvrAddr: '',
        rcvrAddrId: '',
        lng: '',
        lat: '',
        province: '',
        city: '',
        county: '',
      },
      rules: {
        rcvrAddrId: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        rcvrAddr: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        rcvrCtcts: [
          { required: true, message: '请输入收货人', trigger: 'blur' }
        ],
        rcvrTel: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        aeName: [
          { required: true, message: '请选择城市/区域', trigger: 'change' }
        ],
        rcvrNm: [
          { required: true, message: '请输入收货单位', trigger: 'blur' }
        ],
      },
      span: 12,
      regionData: regionData,
      addressOptions: [], // 详细地址的搜索列表
      loading: false, // 详细地址搜索的loading
      isAddressEdit: false, // 输入框有选中地址
    }
  },
  created() {
    // this.getRegionData()
  },
  methods: {
    
    // 展示地图
    async showMap() {
      this.visible = true
      // await this.$nextTick()
      this.$refs.amap.showMap()
    },
    // 地址确认
    async confirmDetailAddr() {
      
    },
    // 发货人确认回调
    confirmCallBack(form) {
      this.form.rcvrAddr = form.address
      this.form.lng = form.longitude
      this.form.lat = form.latitude
      this.form.province = form.province
      this.form.city = form.city
      this.form.county = form.district
      // const provinceCode = TextToCode[form.province]
      // const cityCode = TextToCode[form.province][form.city]
      // const districtCode = TextToCode[form.province][form.city][form.district]
      // this.form.aeName = [provinceCode.code, cityCode.code, districtCode.code]
      this.form.aeName = form.district
      this.form.aeCode = form.adCode
    },
    async resetForm() {
      this.form.province = ''
      this.form.city = ''
      this.form.county = ''
      await this.$nextTick()
      this.$refs['form'].resetFields();
    },
    // 表单确认
    async confirm() {
      try {
        await this.$refs['form'].validate()

        const res = await addReceivingParty({ ...this.form, aeName: this.form.county })
        // console.log(res)
        if(res.data) {
          this.$message.success('添加成功')
          this.$emit('getList')
        }
      } catch (error) {
        return false
      }
      
    },
    // 获取城市列表
    async getRegionData() {
      try {
        const res = await queryAllAreaList()
        this.regionData = res.data
      } catch (error) {
        console.log(error)
      }
    },
    // 高德地图搜索详细地址
    async addressRemoteMethod(value) {
      // console.log(value)
      if (value !== '') {
        this.loading = true;
        try {
          const res = await mapTextSearch({
            key: process.env.VUE_APP_MAP_KEY,
            keywords: value
          })
          // console.log(res)
          this.loading = false
          this.addressOptions = res.data.pois.map(item => {
            return { ...item, label: item.name, value: item.id }
          })
          
        } catch (error) {
          this.loading = false
          console.log(error)
          this.addressOptions = []
        }
        
      } else {
        this.addressOptions = []
        this.isAddressEdit = false
      }
    },
    // 选中详细地址
    handleRcvrAddrChange(value) {
      if(!value) {
        return
      }
      const address = this.addressOptions.find(v => v.id === value)
      this.form.rcvrAddr = address.name
      this.form.province = address.pname
      this.form.city = address.cityname
      this.form.county = address.adname
      this.form.aeCode = address.adcode
      const location = address.location.split(',')
      this.form.lng = location[0]
      this.form.lat = location[1]
      this.form.aeName = address.adname
      // const provinceCode = TextToCode[address.pname]
      // const cityCode = TextToCode[address.pname][address.cityname]
      // const districtCode = TextToCode[address.pname][address.cityname][address.adname]
      // this.form.aeName = [provinceCode.code, cityCode.code, districtCode.code]
      
      this.isAddressEdit = true
      this.$refs.amap.form.longitude = this.form.lng
      this.$refs.amap.form.latitude = this.form.lat
    },
  }
}
</script>

<style lang="scss" scoped>
  .address-info {
    width: calc(100% - 45px);
  }
</style>