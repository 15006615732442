import { addressSourceEnum } from '@/constant/enum.js'

const searchFields = vm => {
  return [
    {
      keyId: 10,
      title: '收货单位',
      field: 'rcvrNm',
      // width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 19,
      title: '收货人姓名',
      field: 'rcvrCtcts',
      // width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 20,
      title: '收货人电话',
      field: 'rcvrTel',
      // width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
  ]
}
const baseColumn = vm => {
  return [
    {
      keyId: 10,
      title: '收货单位',
      field: 'rcvrNm',
      // width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 19,
      title: '收货人',
      field: 'rcvrCtcts',
      // width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 20,
      title: '收货电话',
      field: 'rcvrTel',
      // width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
    {
      keyId: 21,
      title: '收货地址',
      field: 'rcvrAddr',
      // width: 120,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
    },
  ]
}

const operateList = vm => {
  return [
    { name: '新增', btnFn: vm.addAddress },
  ]
}

const tableData = [
  {
    receiverAddress: '浙江省杭州市临平区杭州IFC',
    receiverPhone: '13453454453',
    receiverCompany: '海康威视',
    longitude: '120.305528',
    latitude: '30.402847',
    laAndLg: '120.305528,30.402847',
    source: 1,
    createAt: '2021-12-24 00:12:34'
  }
]
const total = 1
const summary = {
  source: 1,
  instQty: 100,
  instVol: 10,
  instWei: 200,
  outFee: 1500,
  outId: 1,
  payCardFee: 400
}

export default {
  operateList,
  baseColumn,
  searchFields,
  tableData,
  total,
  summary
}